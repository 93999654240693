import * as React from 'react';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { InquiryInner } from '@app/components/Pages/Inquiry/Inquiry';

import profitImage from '@images/profit.png';
import rocket from '@images/rocket.png';
import users from '@images/users.png';
import circle from '@images/circle.svg';

const ForDoctor: React.FC = () => {
	return (
		<div className="cards-block__container home__container text-center">
			<div className="gradient-header">
				<h2 className="cards-block__title">
					If you are a doctor
				</h2>
			</div>
			<div className="doctors-content">
				<div
					className="bg-circle"
					style={{
						backgroundImage: `url(${circle})`,
						backgroundSize: 'contain',
						backgroundPosition: '30% 50%',
						backgroundRepeat: 'no-repeat',
					}}
				/>
				<div className="home-list">
					<div className="home-list__item">
						<div className="home__card-icon__container icon-circle">
							<ImageLazy
								className="home__card__image"
								alt="Onboarding"
								src={rocket}
								style={{ transform: 'translate(-0.1em, 0.1em)' }}
							/>
							<div className="circle" />
						</div>
						<div className="home-list__text">
							<h3 className="home__card__text">
								Onboarding
							</h3>
							<ul className="home__info-list">
								<li>fast and easy setup</li>
								<li>import information from your existing EMR</li>
								<li>advanced customization and security system</li>
							</ul>
						</div>
					</div>
					<div className="home-list__item">
						<div className="home__card-icon__container icon-circle">
							<ImageLazy
								className="home__card__image"
								alt="users"
								src={users}
							/>
							<div className="circle" />
						</div>
						<div className="home-list__text">
							<h3 className="home__card__text">
								Increase Patient Flow
							</h3>
							<ul className="home__info-list">
								<li>automated review invitations</li>
								<li>integrations with popular services</li>
								<li>promotional campaigns and re-engagement</li>
							</ul>
						</div>
					</div>
					<div className="home-list__item">
						<div className="home__card-icon__container icon-circle">
							<ImageLazy className="home__card__image" alt="reviews" src={profitImage} />
							<div className="circle" />
						</div>
						<div className="home-list__text">
							<h3 className="home__card__text">
								Optimize your Profit
							</h3>
							<ul className="home__info-list">
								<li>detailed reports and analytics</li>
								<li>streamline your business processes</li>
								<li>flexible pricing plans based on your needs</li>
								<li>
									<LinkWithPrevLocation to="/payments-processing">
										online payments processing
									</LinkWithPrevLocation>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="home__main home__container dashboard-container home__inquiry-form">
					<h4 className="cards-block__title">Request a Demo</h4>
					<InquiryInner buttonTitle="Send" />
				</div>
			</div>
		</div>
	);
};

export default ForDoctor;
