import * as React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import search from '@images/search.svg';
import calendar from '@images/calendar.svg';
import database from '@images/database.svg';

const ForPatient: React.FC = () => {
	return (
		<div className="cards-block__container home__container text-center patients-block">
			<div className="banner">
				<div className="gradient-header">
					<h2 className="cards-block__title">If you are a patient</h2>
				</div>
			</div>
			<div className="cards-block">
				<div className="home__card">
					<div className="home__card-icon__container icon-circle">
						<ImageLazy
							className="home__card__image"
							alt="search"
							src={search}
						/>
						<div className="circle" />
					</div>
					<h3 className="home__card__text mt10">
						Find a Doctor
					</h3>
					<ul className="home__info-list">
						<li>in-demand specialties</li>
						<li>credentialed providers</li>
						<li>simple and efficient search</li>
						<li>see reviews and ratings from real patients</li>
					</ul>
				</div>
				<div className="home__card">
					<div className="home__card-icon__container icon-circle">
						<ImageLazy
							className="home__card__image"
							alt="calendar"
							src={calendar}
						/>
						<div className="circle" />
					</div>
					<h3 className="home__card__text mt10">
						Online Scheduling
					</h3>
					<ul className="home__info-list">
						<li>book an appointment in just three clicks</li>
						<li>add text or email appointment reminders</li>
						<li>save time by filling out necessary forms online</li>
						<li>sign consent forms and answer questionnaires</li>
					</ul>
				</div>
				<div className="home__card">
					<div className="home__card-icon__container icon-circle">
						<ImageLazy
							className="home__card__image"
							alt="database"
							src={database}
						/>
						<div className="circle" />
					</div>
					<h3 className="home__card__text mt10">
						After Your Appointment
					</h3>
					<ul className="home__info-list">
						<li>pay your bill online</li>
						<li>access visit summaries and lab results</li>
						<li>sign up for promotions and discounts</li>
						<li>manage your future appointments</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ForPatient;
