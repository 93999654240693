import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Doctor } from '@app/objects/Doctor';
import DoctorCard from '@app/components/UI/DoctorCard/DoctorCardHome';

const TopDoctors: React.FC<{ items: Array<Doctor> }> = ({ items }) => {
	return (
		<div className="home__container doctor-card-list" style={{ paddingLeft: 0, paddingRight: 0 }}>
			<div className="mb10 doctors-list">
				{items.map((doctor: Doctor) => <DoctorCard
					doctor={doctor}
					key={doctor.id}
				/>)}
			</div>
			<div className="text-center">
				<NavLink className="btn btn-primary" to="/doctors">
					Find a Doctor
				</NavLink>
			</div>
		</div>
	);
};

export default TopDoctors;
