import * as React from 'react';
import { NavLink } from 'react-router-dom';

import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';

import ForPatient from '@app/components/Pages/Home/ForPatient';
import ForDoctor from '@app/components/Pages/Home/ForDoctor';
import TopDoctors from '@app/components/Pages/Home/TopDoctors';

import '@app/scss/pages/home.scss';
import '@app/scss/pages/catalog.scss';
import SpecialtyTag from '@app/components/Pages/Specialties/SpecialtyTag';
import HomeMenu from '@app/components/Pages/Home/HomeMenu';

const HomeInner: React.FC<{ page }> = ({ page }) => {
	return (<div className="home">
		<div>
			{page?.langs?.length > 0 && (
				<main className="home__main home__container">
					<div className="site-headline site-headline_with-button clearfix">
						<div className="home__title">
							<h1 className="pull-left">{page.langs[0].title}</h1>
							{/* <HomeMenu /> */}
						</div>
					</div>
					<div>
						{page.langs[0].body ? <div dangerouslySetInnerHTML={{ __html: `${page.langs[0].body}` }} /> : '-'}
					</div>
				</main>
			)}
			<ForPatient />
			{page?.item?.doctors && <TopDoctors items={page?.item?.doctors} />}
			<ForDoctor />
			{page?.item?.specialties?.length && <div className="home__container">
				<div className="gradient-header">
					<h2 className="cards-block__title text-center">Available Specialties</h2>
				</div>
				<div className="catalog home__main">
					<ul className="catalog__list">
						{page.item.specialties.slice(0, 8).map((item) => <li key={item.id} className="catalog__list__item">
							<SpecialtyTag specialty={item} />
						</li>)}
					</ul>
					<div className="text-center">
						<NavLink className="btn btn-primary" to="/specialties">
							See All
						</NavLink>
					</div>
				</div>
			</div>}
		</div>
	</div>);
};

const Home: React.FC = () => {
	return (
		<ServerPageProvider
			path="home"
			loader={<LogoLoaderPage />}
			inner={(page) => <HomeInner page={page} />}
		/>
	);
};

export default Home;
